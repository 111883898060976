<template>
	<div>
		<creatsuccess :msg="showtype">
			<div class="suppierDetail">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="submit-form" style="margin-bottom:80px;" v-loading='loading'>
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>基本信息</span>
						</div>
						<div class="content">
							<el-form-item label="优惠券名称：" prop="CouponName">
								<el-input v-model="ruleForm.CouponName" :disabled="currentEditStatus==0" placeholder="最多输入10个字" style="width:200px"
								maxlength="10"></el-input>
							</el-form-item>
							<el-form-item label="优惠券总量：" prop="AllCount">
								<el-input :disabled="currentEditStatus==0" v-model="ruleForm.AllCount" placeholder="最多100000000张" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								onblur="this.v();if (value==0||value=='-'){value =''}" style="width:200px"></el-input>
								<div class="remarks">
									优惠券总量设置后，只可增加，不可减少
								</div>
							</el-form-item>
							<!-- 适用商品 -->
							<el-form-item label="适用商品：" required>
								<!-- <el-radio-group  @change="changeRangeType" > -->
								<div class="radio">
									<el-radio :disabled="currentEditStatus==0" @change="changeRangeType" v-model="ruleForm.ActivityRangeType" :label="0">全部商品适用</el-radio>
								</div>
								<div class="radio">
									<el-radio :disabled="currentEditStatus==0" @change="changeRangeType" v-model="ruleForm.ActivityRangeType" :label="1">指定商品适用</el-radio>
									<span v-if="ruleForm.ActivityRangeType==1&&currentEditStatus!=0" style="color:#409EFF;cursor: pointer;" :class="{'is-error-button':ruleForm.ActivityRangeType==1}" @click="handleSelectProDialog">选择商品</span>
								</div>
								<div class="radio">
									<el-radio :disabled="currentEditStatus==0" @change="changeRangeType" v-model="ruleForm.ActivityRangeType" :label="2">指定商品不适用</el-radio>
									<span v-if="ruleForm.ActivityRangeType==2&&currentEditStatus!=0" style="color:#409EFF;cursor: pointer;" :class="{'is-error-button':ruleForm.ActivityRangeType==2}"  @click="handleSelectProDialog">选择商品</span>
								</div>
							</el-form-item>
							<!-- list -->
							<el-form-item v-if="ruleForm.ActivityRangeType==1">
								<el-table border :data="ruleForm.pageActivityCouponProductList " style="width: 100%">
									<el-table-column label="商品" width="300px">
										<template slot-scope="scope">
											<div class="dialog-name-content">
												<img :src='imgUrl+scope.row.ImgUrl' />
												<div class="right">
													<div class="name">
														<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

													</div>
													<span>{{scope.row.Remark}}</span>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="ProductPrice" label="价格"></el-table-column>
									<el-table-column prop="Stock" label="库存"></el-table-column>
									<el-table-column label="操作" v-if="currentEditStatus!=0">
										<template slot-scope="scope">
											<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row.Id,scope.$index)" type="text" size="small">删除</el-button>
										</template>
									</el-table-column>
								</el-table>

								<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
								@current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10,20]" :page-size="page.size"
								layout="total, sizes, prev, pager, next, jumper" :total="page.total">
								</el-pagination>
							</el-form-item>
							<el-form-item v-if="ruleForm.ActivityRangeType==2">
								<el-table border :data="ruleForm.pageActivityCouponProductList " style="width: 100%">
									<el-table-column label="商品" width="300px">
										<template slot-scope="scope">
											<div class="dialog-name-content">
												<img :src='imgUrl+scope.row.ImgUrl' />
												<div class="right">
													<div class="name">{{scope.row.Name}}</div>
													<span>{{scope.row.Remark}}</span>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="ProductPrice" label="价格"></el-table-column>
									<el-table-column prop="Stock" label="库存"></el-table-column>
									<el-table-column label="操作" v-if="currentEditStatus!=0">
										<template slot-scope="scope">
											<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row.Id,scope.$index)" type="text" size="small">删除</el-button>
										</template>
									</el-table-column>
								</el-table>

								<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
								@current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10,20]" :page-size="page.size"
								layout="total, sizes, prev, pager, next, jumper" :total="page.total">
								</el-pagination>
							</el-form-item>
							<!-- 适用门槛 -->
							<el-form-item label="适用门槛：" required>
								<el-radio-group :disabled="currentEditStatus==0" v-model="FullMoneyRadio" style='margin-top:10px;' @change="changeRangeRadio">
									<el-radio :label="0" class="radio-group">无门槛使用</el-radio>
									<br>
									<el-radio :label="1" class="radio-group" style='margin-top:10px;'>
										消费满
										<el-form-item prop="FullMoney" style="margin:0 10px;" ref="editDisRangeValue" class="el-form-date-pick">
											<el-input style="width:200px;" type="number" @change="checkPriceFullMoney"  maxLength='6' :disabled="FullMoneyRadio==0||currentEditStatus==0"
											v-model="ruleForm.FullMoney"></el-input>
										</el-form-item>
										元可用
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<!-- 优惠券面额 -->

							<el-form-item label="优惠券面额：" v-if="currentEditType==0" prop="MinMoney">
								减
								<el-input style="margin:0 10px;width:200px;" type="number" @change="checkPriceMinMoney" :disabled='this.currentEditId!=""'
								v-model="ruleForm.MinMoney"></el-input>
								元
							</el-form-item>
							<el-form-item label="优惠券面额：" v-if="currentEditType==1" prop="Discount">
								打
								<el-input style="margin:0 10px;width:200px;" :max="9.9" :disabled='this.currentEditId!=""' v-model="ruleForm.Discount"
								@change="handleChangeDicsount"></el-input>
								折
							</el-form-item>
							<el-form-item label="优惠券面额：" required v-if="currentEditType==2">
								减
								<el-form-item prop="MinMoney" style="display:inline-block;margin:0 10px;">
									<el-input type="number" :max="9.9" @change="checkPriceMinMoney"  :disabled='this.currentEditId!=""' v-model="ruleForm.MinMoney"></el-input>
								</el-form-item>
								元
								<span style="margin:0 5px">~</span>
								<el-form-item prop="MaxMoney" style="display:inline-block;margin:0 10px;">
									<el-input type="number" :max="9.9" @change="checkPriceMaxMoney"  :disabled='this.currentEditId!=""' v-model="ruleForm.MaxMoney"></el-input>
								</el-form-item>
								元
							</el-form-item>
						</div>
					</el-card>
					<!-- card2 -->
					<el-card class="box-card" style="margin-top:30px">
						<div slot="header" class="clearfix">
							<span>领取和使用</span>
						</div>
						<div class="content">
							<!-- 使用期限 -->
							<el-form-item label="使用期限" required>
								<el-radio-group :disabled='this.currentEditId!=""' v-model="ruleForm.ValidType" class="radio-group" @change="changeDeadlineRadio">
									<el-radio class="radio-group" :label="0">
										<el-form-item prop="StartTime" ref="editDisDeadlineStart" class="el-form-date-pick">
											<el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :disabled="ruleForm.ValidType!=0||this.currentEditId!=''"
											v-model="ruleForm.StartTime" type="datetime" placeholder="开始时间" :picker-options='maxOptions'>
											</el-date-picker>
										</el-form-item>
										<span style="margin:0 10px;">~</span>
										<el-form-item prop="EndTime" ref="editDisDeadlineEnd" class="el-form-date-pick">
											<el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :disabled="ruleForm.ValidType!=0||this.currentEditId!=''"
											v-model="ruleForm.EndTime" type="datetime" placeholder="结束时间" :picker-options='minOptions'>
											</el-date-picker>
										</el-form-item>
									</el-radio>
									<br>
									<el-radio class="radio-group" :label="1">
										领取当日起
										<el-form-item prop="ValidTimeToday" style="margin:10px 10px 0px 10px;" ref="editDisDeadlineValue" class="el-form-date-pick">
											<el-input style="margin-top:10px;width:200px" :disabled="ruleForm.ValidType!=1||this.currentEditId!=''"
											v-model="ruleForm.ValidTimeToday" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
											onblur="this.v();if (value>100000000 || value==0||value=='-'){value =''}"></el-input>
										</el-form-item>
										天内使用
									</el-radio>
									<br>
									<el-radio class="radio-group" :label="2">
										领取次日起
										<el-form-item prop="ValidTimeNext" style="margin:10px 10px 0px 10px;" ref="editDisDeadlineNextValue" class="el-form-date-pick">
											<el-input style="margin-top:10px;width:200px" :disabled="ruleForm.ValidType!=2||this.currentEditId!=''"
											v-model="ruleForm.ValidTimeNext" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
											onblur="this.v();if (value>100000000 || value==0||value=='-'){value =''}"></el-input>
										</el-form-item>
										天内使用
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<!-- 使用说明 -->
							<el-form-item label="使用说明:">
								<!-- :disabled='this.currentEditId!=""' -->
								<el-input :disabled="currentEditStatus==0" v-model="ruleForm.UseInstruction" type="textarea" maxlength="300"
								:rows="5" style="width: 400px;"></el-input>
							</el-form-item>
							<!-- 领取限制 -->
							<el-form-item label="领取限制:" required>
								<el-radio-group :disabled="this.currentEditId!=''" v-model="receiveLimitRadio" style='margin-top:10px;' @change="changeRestrictionRadio">
									<el-radio :label="0" class="radio-group">不限制次数</el-radio>
									<br>
									<el-radio :label="1" class="radio-group" style='margin-top:10px;'>
										每人限领
										<el-form-item prop="ReceiveLimit" style="margin:0 10px;" ref="editDisRestrictionValue" class="el-form-date-pick">
											<el-input onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
											onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
											maxlength="30" style="width:200px;" :disabled="receiveLimitRadio==0||this.currentEditId!=''" v-model="ruleForm.ReceiveLimit">
											</el-input>
										</el-form-item>
										次
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<!-- 公开领取 -->
							<el-form-item label="公开领取:">
								<el-checkbox v-model="ruleForm.IsPublicReceive">优惠券允许公开领取</el-checkbox>
								<div style="color: #999;">开启后买家可在商品详情页领取该优惠券</div>
							</el-form-item>
						</div>

					</el-card>

					<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
						<select-produce api="activitycouponChoseproductlist" :produceData="produceData" :params="['KeyWords', 'ProductType', 'ProductGroupId', 'ProductBrandId', 'State','ActivityCouponId','AcctivityRangeType',]"
						:selectedData="ruleForm.ActivityCouponProductList" @getSelectList="getSelectList" :visible.sync="selectProDialog"
						v-if="selectProDialog"></select-produce>
					</el-dialog>

					<!-- </el-dialog> -->
					<div class="footer" v-if="currentEditStatus!=0">
						<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
						<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
					</div>
					<div class="footer" v-if="currentEditStatus==0">
						<el-button style="width:240px;" @click="suppilerBack">关闭</el-button>
					</div>
				</el-form>
			</div>
		</creatsuccess>
	</div>
</template>

<script>
	import config from '@/config/index'
	import selectProduce from './selectMultiPro'
	import apiList from '@/api/other'
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
	export default {
		components: {
			selectProduce,
			creatsuccess
		},
		data() {
			var checkCouponName = (rule, value, callback) =>{
				if(!value){
					return callback(new Error('请输入优惠券名称'));
				}else if(!value.trim() ){
					return callback(new Error('请输入优惠券名称'));
				}else{
					return callback()
				}
			};
			var checkAllCount = (rule, value, callback) => {
				if (value > 100000000) {
					this.ruleForm.AllCount = ''
					return callback(new Error('优惠券总量超出限制，请重新设置'));
				} else {
					if (!value) {
						return callback(new Error('请输入优惠券总量'));
					} else {
						return callback();
					}
				}
			};
			var checkFullMoney = (rule, value, callback) => {
				if (this.FullMoneyRadio == 1 && value === '') {
					return callback(new Error('请设置使用门槛'));
				} else if (this.FullMoneyRadio == 1 && value <= 0) {
					return callback(new Error('优惠门槛金额需大于0，最多2位小数'));
				} else if(this.FullMoneyRadio == 1 && value >9999999){
					return callback(new Error('使用门槛请设置在0.01-9999999之间，最多2位小数'));
				}else {
					return callback();
				}
			};
			var checkValidDiscount = (rule, value, callback) => {
				if (value === 0 || value > 10 || value < 1) {
					return callback(new Error('折扣请设置在1-9.9折之间，最多1位小数'));
				} else {
					if (!value) {
						return callback(new Error('请设置优惠券面值'));
					} else {
						return callback();
					}
				}
			};
			var checkValidMinMoney = (rule, value, callback) => {
				if (this.currentEditType == 0) {
					if (value == 0) {
						this.ruleForm.MinMoney = ''
						return callback(new Error('优惠券面值大于0，最多2位小数'));
					} 
					if (!value) {
						return callback(new Error('请设置优惠券面值'));
					} 
				} else {
					if (value == 0) {
						this.ruleForm.MinMoney = ''
						return callback(new Error('随机金额大于0，最多2位小数'));
					}
					if (!value) {
						return callback(new Error('请设置随机金额范围'));
					}
				}
				if(value>9999999||value<0){
						this.ruleForm.MinMoney = ''
						return callback(new Error('优惠券面值请设置在0.01-9999999之间，最多2小数'));
				}
				return callback();
			};
			var checkValidMaxMoney = (rule, value, callback) => {
				if (value == 0) {
					this.ruleForm.MaxMoney = ''
					return callback(new Error('随机金额大于0，最多2位小数'));
				} else {
					if (!value) {
						return callback(new Error('请设置随机金额范围'));
					} else {
						return callback();
					}
				}
			};
			var checkValidStartTime = (rule, value, callback) => {
				if (this.ruleForm.ValidType == 0 && (!value)) {
					callback(new Error('请输入时间期限'));
				} else {
					return callback()
				}
			};
			var checkValidEndTime = (rule, value, callback) => {
				if (this.ruleForm.ValidType == 0 && (!value)) {
					callback(new Error('请输入时间期限'));
				} else {
					return callback()
				}
			};
			var checkValidTimeToday = (rule, value, callback) => {
				if (this.ruleForm.ValidType == 1 && (!value)) {
					callback(new Error('请输入时间期限'));
				} else {
					return callback()
				}
			};
			var checkValidTimeNext = (rule, value, callback) => {
				if (this.ruleForm.ValidType == 2 && (!value)) {
					callback(new Error('请输入时间期限'));
				} else {
					return callback()
				}
			};
			var checkReceiveLimit = (rule, value, callback) => {
				if (this.receiveLimitRadio == 1 && (!value || value == '' || value == '0')) {
					callback(new Error('请输入领取限制'));
				} else if(this.receiveLimitRadio == 1 && value >1000){
					callback(new Error('限领次数请设置在1-1000之间，整数'));
				}else {
					callback();
				}
			};

			return {
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				loading: false,
				ruleForm: {
					CouponName: '',
					AllCount: '',
					ActivityRangeType: 0,
					ActivityCouponProductList: [],
					FullMoney: '',
					ValidType: 0,
					Discount: '',
					StartTime: '',
					EndTime: '',
					ValidTimeToday: '',
					ValidTimeNext: '',
					deadlineNextValue: '',
					UseInstruction: '',
					restriction: '1',
					restrictionValue: ''
				},
				ProductList: [],
				FullMoneyRadio: 0,
				receiveLimitRadio: 0,
				currentCount: '',
				rules: {
					CouponName: [{
						required: true,
						trigger: 'blur',
						validator:checkCouponName
					}],
					AllCount: [{
						trigger: 'blur',
						required: true,
						validator: checkAllCount
					}],
					FullMoney: [{
						trigger: 'blur',
						validator: checkFullMoney
					}],
					Discount: [{
						// required: true,
						// message: '请设置优惠券面值',
						// trigger: 'blur'
						required: true,
						trigger: 'blur',
						validator: checkValidDiscount
					}],
					MinMoney: [{
						trigger: 'blur',
						required: true,
						validator: checkValidMinMoney
					}],
					MaxMoney: [{
						trigger: 'blur',
						required: true,
						validator: checkValidMaxMoney
					}],
					StartTime: [{
						trigger: 'blur',
						validator: checkValidStartTime
					}],
					EndTime: [{
						trigger: 'blur',
						validator: checkValidEndTime
					}],
					ValidTimeToday: [{
						trigger: 'blur',
						validator: checkValidTimeToday
					}],
					ValidTimeNext: [{
						trigger: 'blur',
						validator: checkValidTimeNext
					}],
					restriction: [{
						required: true,
						message: '请选择领取限制',
						trigger: 'change'
					}],
					// restrictionValue: [{
					//   trigger: 'blur',
					//   validator:checkRestrictionValue
					// }],
					ReceiveLimit: [{
						trigger: 'blur',
						validator: checkReceiveLimit
					}],
				},
				oriData: {
					ActivityCouponProductList: [],
					FullMoney: 0
				},
				page: {
					total: 0,
					current: 1,
					size: 5
				},
				ProductIdList: [],
				selectProDialog: false,
				multipleSelection: [],
				currentEditId: '',
				currentEditType: null,
				currentEditStatus: 0,
				currentEditCopyId: '',
				produceData: {
					id: '',
					range: 0
				},


				showtype:{
					issuccess:false,
					id:0,
					type:0,
					suctype:1,
				},
			}
		},
		created() {
			this.currentEditId = this.$route.query.id || ''
			this.currentEditType = this.$route.query.type
			this.currentEditStatus = this.$route.query.status
			this.currentEditCopyId = this.$route.query.copyId || ''
			if (this.currentEditCopyId != '') {
				this.currentEditId = this.currentEditCopyId
				this.getData()
			}
			if (this.currentEditId != '') {
				this.getData()
			}
			//传值给选择商品的组件
			this.produceData.id = this.$route.query.id

		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		methods: {
			handleChangeDicsount(value) {
				value = (value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
				if (value > 9.9) {
					value = null
				} else if (value < 1) {
					value = null
				}
				this.ruleForm.Discount = value
			},
			checkPriceMinMoney(value) {
				value = value.match(/^\d*(\.?\d{0,2})/g)[0] ? value.match(/^\d*(\.?\d{0,2})/g)[0] : ''
				this.ruleForm.MinMoney = value
			},
			checkPriceMaxMoney(value) {
				value = value.match(/^\d*(\.?\d{0,2})/g)[0] ? value.match(/^\d*(\.?\d{0,2})/g)[0] : ''
				this.ruleForm.MaxMoney = value
			},
			checkPriceFullMoney(value){
				value = value.match(/^\d*(\.?\d{0,2})/g)[0] ? value.match(/^\d*(\.?\d{0,2})/g)[0] : ''
				this.ruleForm.FullMoney = value
			},
			changeRangeRadio(e) {
				if (e == 0) {
					this.ruleForm.FullMoney = ''
					this.$refs['editDisRangeValue'].clearValidate()
				}
			},
			changeDeadlineRadio(e) {
				if (e == 0) {
					this.ruleForm.ValidTimeNext = ''
					this.ruleForm.ValidTimeToday = ''
					this.$refs['editDisDeadlineNextValue'].clearValidate()
					this.$refs['editDisDeadlineValue'].clearValidate()
				} else if (e == 1) {
					this.ruleForm.EndTime = ''
					this.ruleForm.StartTime = ''
					this.ruleForm.ValidTimeNext = ''
					this.$refs['editDisDeadlineStart'].clearValidate()
					this.$refs['editDisDeadlineEnd'].clearValidate()
					this.$refs['editDisDeadlineNextValue'].clearValidate()
				} else {
					this.ruleForm.EndTime = ''
					this.ruleForm.StartTime = ''
					this.ruleForm.ValidTimeToday = ''
					this.$refs['editDisDeadlineStart'].clearValidate()
					this.$refs['editDisDeadlineEnd'].clearValidate()
					this.$refs['editDisDeadlineValue'].clearValidate()
				}
			},
			changeRangeType(e) {
				this.produceData.range = e
				this.ruleForm.ActivityCouponProductList = []
				this.ruleForm.pageActivityCouponProductList = []
				this.page = {
						total: 0,
						current: 1,
						size: 5
					},
					this.$forceUpdate()
			},
			changeRestrictionRadio(e) {
				if (e == 0) {
					this.ruleForm.ReceiveLimit = ''
					this.$refs['editDisRestrictionValue'].clearValidate()
				}
			},
			//数据处理
			initForm() {
				this.ruleForm.EndTime = (this.ruleForm.EndTime == "2000-01-01 00:00:00") ? '' : this.ruleForm.EndTime
				this.ruleForm.StartTime = (this.ruleForm.StartTime == "2000-01-01 00:00:00") ? '' : this.ruleForm.StartTime
				this.FullMoneyRadio = (this.ruleForm.FullMoney == 0) ? 0 : 1;
				this.ruleForm.ValidTimeToday = (this.ruleForm.ValidTimeToday == 0) ? '' : this.ruleForm.ValidTimeToday;
				this.ruleForm.ValidTimeNext = (this.ruleForm.ValidTimeNext == 0) ? '' : this.ruleForm.ValidTimeNext;
				this.receiveLimitRadio = (this.ruleForm.ReceiveLimit == 0) ? 0 : 1;
				this.ruleForm.ReceiveLimit = (this.ruleForm.ReceiveLimit == 0) ? '' : this.ruleForm.ReceiveLimit
				this.produceData.range = this.ruleForm.ActivityRangeType
				this.ruleForm.ActivityCouponProductList.forEach(item => {
					this.ProductIdList.push(item.Id)
				})
				this.ruleForm.Discount = (this.ruleForm.Discount == 0) ? '' : this.ruleForm.Discount;
				this.ruleForm.FullMoney = (this.ruleForm.FullMoney == 0) ? '' : this.ruleForm.FullMoney;
				//商品分页
				this.page.total = this.ruleForm.ActivityCouponProductList.length;
				let pagination = this.ruleForm.ActivityCouponProductList.slice(0, 5);
				this.ruleForm.pageActivityCouponProductList = pagination
				//如果是复制，不复制期限，由于复制是根据id值获取数据之后复制数据，需要将id值改为空
				if (this.currentEditCopyId != '') {
					this.currentEditId = ''
					this.ruleForm.EndTime = ''
					this.ruleForm.StartTime = ''
					this.ruleForm.ValidTimeToday = ''
					this.ruleForm.ValidTimeNext = ''
					this.ruleForm.ValidType = 0
				}
				this.$forceUpdate();
			},
			// 编辑时初始数据
			async getData() {
				this.loading = true;
				try {
					let data = {
						Id: this.currentEditId
					}
					let result = await apiList.activitycouponActivitycoupondetail(data);
					this.ruleForm = result.Result || this.ruleForm;
					this.oriData.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList
					this.oriData.FullMoney = this.ruleForm.FullMoney

					this.initForm()
				} catch (error) {
					this.loading = false
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			// 取消
			suppilerCansel() {
				this.$router.push({
					path: '/market/coupon/index'
				});
			},
			suppilerBack() {
				this.$router.push({
					path: '/market/coupon/index'
				});
			},
			async supilerData() {
				this.loading = true;
				try {
					let data = {
						Id: this.currentEditId,
						UseType: this.currentEditType,
						CouponName: this.ruleForm.CouponName,
						AllCount: this.ruleForm.AllCount,
						ActivityRangeType: this.ruleForm.ActivityRangeType,
						ProductIdList: this.ProductIdList,
						ActivityCouponProductList: this.ruleForm.ActivityCouponProductList,
						FullMoney: this.ruleForm.FullMoney,
						MinMoney: this.ruleForm.MinMoney,
						MaxMoney: this.ruleForm.MaxMoney,
						Discount: this.ruleForm.Discount,
						ValidType: this.ruleForm.ValidType,
						ValidTimeToday: this.ruleForm.ValidTimeToday,
						ValidTimeNext: this.ruleForm.ValidTimeNext,
						StartTime: this.ruleForm.StartTime,
						EndTime: this.ruleForm.EndTime,
						ReceiveLimit: this.ruleForm.ReceiveLimit,
						UseInstruction: this.ruleForm.UseInstruction,
						IsPublicReceive : this.ruleForm.IsPublicReceive 
					}
					let result = await apiList.activitycouponActivitycouponedit(data);
					
					if(result.IsSuccess){
						this.$message.success('操作成功')
						// console.log(result.Result)
						// console.log(123)
						if(this.$route.query.copyId||!this.$route.query.id){
							this.showtype = {
								issuccess:true,
								id:result.Result.Id,
								type:this.$route.query.type,
								suctype:1,
							}
						}else{
							this.$router.push({
								path: '/market/coupon/index'
							});
						}
						
						// console.log(this.showtype.issuccess)
					}
				} catch (error) {
					// console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			// 保存
			saveSupiler(ruleForm) {
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						let length = this.ruleForm.ActivityCouponProductList.length
						if (this.ruleForm.ActivityRangeType != 0 && length == 0) {
							
						  this.$common.completeFormInformation('请选择商品','is-error-button')
							// this.$message({showClose: true,
							// 	type: 'error',
							// 	message: '请选择商品'
							// });
						} else {
							//若修改适用商品或使用门槛
							let index = (this.currentEditId != '') && ((this.oriData.ActivityCouponProductList != this.ruleForm.ActivityCouponProductList) ||
								(this.oriData.FullMoney != this.ruleForm.FullMoney))
							if (index) {
								this.$confirm('优惠券适用商品或使用门槛修改后，已经发放的优惠券也会同时更新为最新保存的数据，请确认您已了解修改已领取优惠券信息带来的权益变化及损失情况再进行修改。请提前告知消费者。', '提示', {
									confirmButtonText: '我已了解，确认修改',
									cancelButtonText: '关闭',
									type: 'warning'
								}).then(() => {
									this.supilerData()
								}).catch(() => {
									this.$message({showClose: true,
										type: 'info',
										message: '已取消修改'
									});
								})
							} else {
								//若没有
								this.supilerData()
							}
						}
					} else {
						this.$common.completeFormInformation('请完善优惠券信息','is-error')
						return false;
					}
				});
			},

			handleSelectProDialog() {
				this.selectProDialog = true
			},
			// 删除指定商品
			deleteSortPro(id, index) {

				this.ruleForm.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList.filter(t=>{
					if(t.Id!=id){
						return t
					}
				})
				this.ProductIdList = this.ProductIdList.filter(t=>{
					if(t!=id){
						return t
					}
				})
				if ((this.ruleForm.ActivityCouponProductList.length) % this.page.size == 0 && this.page.current > 1) {
					this.page.current = this.page.current - 1;
				}
				this.paginationPro()
			},
			//获取选择商品的列表
			getSelectList(data) {
				this.ProductIdList = []
				this.ruleForm.ActivityCouponProductList = data
				this.selectProDialog = false;
				data.forEach(t => {
					this.ProductIdList.push(t.Id)
				});
				this.paginationPro()

			},
			//商品分页
			paginationPro() {
				this.page.total = this.ruleForm.ActivityCouponProductList.length;
				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.ruleForm.ActivityCouponProductList.slice(start, end);
				this.ruleForm.pageActivityCouponProductList = pagination
				this.$forceUpdate()
			},
			handleSizeChange(val) {
				this.page.size = val
				this.paginationPro()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.paginationPro()
			},
		}
	}
</script>

<style lang="less" scoped>
	.suppierDetail {
		// margin: 30px;
		padding: 10px;

		.remarks {
			color: #bbb;
			font-size: 14px;
		}

		.parent-box {
			position: relative;

			.close-bosx {
				position: absolute;
				right: -5px;
				top: -13px;
				font-size: 18px;
			}
		}

		.markRed {
			position: absolute;
			left: -94px;
			top: 5px;
			color: red;
			font-size: 18px;
		}
	}

	.table-container {
		position: relative;
		overflow: hidden;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}

	.radio-group {

		// display: block;
		.el-form-date-pick {
			display: inline-block;
		}
	}

	.footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
	}

	.ori-input {
		height: 36px !important;
		width: 200px !important;
		padding: 0 5px;
		line-height: 32px;
		border: 1px solid #ddd;
		border-radius: 5px;
		// margin:0 10px;
	}

	input:disabled {
		background-color: #F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
		padding: 0px 10px;
	}

	::v-deepinput[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep .number__input {
		border: 1px solid #ccc;
	}
</style>
